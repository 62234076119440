import jwtDecode from 'jwt-decode';

/* eslint class-methods-use-this: 0 */

class AuthenticationHelper {
  isJwtTokenStored() {
    // if jwtToken is a falsy value, then return false
    return sessionStorage.jwtToken ? true : false;
  }

  getJwtToken() {
    return (this.isJwtTokenStored()) ? sessionStorage.jwtToken : null;
  }

  storeJwtToken(jwtToken) {
    if (jwtToken.indexOf('Bearer ') >= 0) {
      jwtToken = jwtToken.replace('Bearer ', '');
    }
    sessionStorage.setItem('jwtToken', jwtToken);
    return null;
  }

  removeJwtToken() {
    sessionStorage.removeItem('jwtToken');
    return null;
  }

  hasJwtPayloadKey(key) {
    if (this.isJwtTokenStored()) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key] !== undefined;
    }
    return null;
  }

  getValueFromJwtPayload(key) {
    if (this.isJwtTokenStored) {
      const decodedToken = jwtDecode(sessionStorage.jwtToken);
      if (!key) {
        return null;
      }
      return decodedToken[key];
    }
    return null;
  }

  getDecodedToken() {
    return (this.isJwtTokenStored) ? jwtDecode(sessionStorage.jwtToken) : null;
  }
}
export default new AuthenticationHelper();
